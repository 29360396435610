import React, { useEffect, useRef, useContext, useState, useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import Box from '@mui/material/Box';
import { Button } from '@mui/material';
import Grid from '@mui/material/Grid';
import LinearProgress from '@mui/material/LinearProgress';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';

import { AppContext } from '@/context/AppContext';
import * as utils from '@/libs/utils';
import * as audit from '@/libs/AuditTrailOperations';
import TRANS_MAP from '@/i18n/TransMap';
import CONST from '@/const';
import AuditTrailTableHead from './TableHead';

const AuditTrailTable = ({ mode }) => {
    const {
        openAlertDialog,
        filters, resetFilters,
        auditTrailDatas, setAuditTrailDatas,
    } = useContext(AppContext);
    
    const [isLoading, setIsLoading] = useState(false);
    const [hasScrollBar, setHasScrollBar] = useState(false);

    const { t } = useTranslation();
    const scrollRef = useRef(null);

    const getStatusMessage = (slug) => {
        return <span className={`status-${slug}`}>{t(slug)}</span>;
    }

    const getRowQuantity = (row) => {
        return row.id !== "" ? `${row.quantity}/${row.doc_quantity}` : "N/A";
    }

    const parseFilters = useCallback(() => {
        let filterQuery = "";
        for (const [key, value] of Object.entries(filters)) {
            if (value)
                filterQuery += `&${key}=${value}`
        }
        filterQuery += `&mode=${mode}`;
        return filterQuery;
    }, [mode, filters]);

    const loadMoreDatas = useCallback(async () => {
        let queryFilters = parseFilters();
        queryFilters += `&offset=${auditTrailDatas.length}`
        return audit.getAuditTrailDatas({ filters: queryFilters })
    }, [parseFilters, auditTrailDatas.length]);

    const onScrollTableBottom = useCallback(async () => {
        setIsLoading(true);
        try {
            const newRows = await loadMoreDatas();
            /* istanbul ignore next */
            if (newRows.length)
                setAuditTrailDatas([...auditTrailDatas, ...newRows]);
        } catch (error) {
            openAlertDialog({ title: TRANS_MAP.warning, content: TRANS_MAP.messageTechnicalError, infos: error.message })
            console.error(`Error Loading more datas: ${error}`);
        }
        setIsLoading(false);
    }, [auditTrailDatas, loadMoreDatas, openAlertDialog, setAuditTrailDatas]);

    // Out of coverage, need to find a solution to simulate scroll
    /* istanbul ignore next */
    const handleOnScroll = useCallback((e) => {
        let bottom = e.target.offsetHeight + e.target.scrollTop >= e.target.scrollHeight;
        if (bottom)
            onScrollTableBottom();
    }, [onScrollTableBottom]);

    useEffect(() => {
        const table = scrollRef.current;
        // subscribe event
        table.addEventListener(CONST.events.scroll, handleOnScroll);
        setHasScrollBar(table.scrollHeight > table.clientHeight);
        return () => {
            // unsubscribe event
            table.removeEventListener(CONST.events.scroll, handleOnScroll);
        };
    }, [handleOnScroll]);

    // Update displayed rows according to filters (that are query strings)
    useEffect(() => {
        const handleGetDatas = async (filtersQuery) => {
            try {
                setIsLoading(true);
                setAuditTrailDatas(await audit.getAuditTrailDatas({ filters: filtersQuery }));
            } catch (error) {
                resetFilters();
                openAlertDialog({ title: TRANS_MAP.warning, content: TRANS_MAP.messageTechnicalError, infos: error.message })
                console.error(error)
            }
            setIsLoading(false);
        }
        handleGetDatas(parseFilters());
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters, parseFilters, setAuditTrailDatas]);

    const values = (row, index) => {
        const cols = [{ key:"item", className: "table-bold-col", value: row.item, scope: "row", padding: "normal" },
        { key:"batch", value: row.batch },
        { key:"country", value: row.country },
        mode === audit.AVAILABLE_MODE.OUT ? { key:"shiporder", value: row.shiporder } : { key:"receiving_plant", value: row.receiving_plant },
        mode === audit.AVAILABLE_MODE.IN ? { key:"serial_number", value: row.serial_number } : null,
        { key:"document_type", value: utils.getDocTypeAbbrFromCode(row.document_type), align: "center" },
        { key:"part_number", value: (row.part_number || row.label_part), align: "center" },
        { key:"languages", value: row.languages },
        { key:"status", value: getStatusMessage(row.status), className: "table-bold-col", align: "center" },
        mode === audit.AVAILABLE_MODE.OUT ? { key:"quantity", value: getRowQuantity(row) } : null,
        { key:"modified_by", className: "table-wraptext", value: row.modified_by, align: "center" },
        { key:"updated_at", value: utils.dateFromTimestamp({ rawDate: row.updated_at }) }
        ].filter(Boolean);

        return (<TableRow
            hover
            tabIndex={-1}
            key={index}
        >
            {
                cols.map(col =>
                    <TableCell
                        align={col.align}
                        scope={col.scope}
                        padding={col.padding}
                        key={col.key}
                        className={col.className}
                    >
                        {col.value}
                    </TableCell>
                )
            }
        </TableRow>)

    }
    return (
        <Box sx={{ width: '100%' }} id="audit-table-box">
            <Paper sx={{ width: '100%', mb: 2 }}>
                <TableContainer id="audit-trail-tablecontainer"
                    ref={scrollRef}
                >
                    <Table
                        stickyHeader
                        sx={{ minWidth: 600 }}
                        aria-labelledby="History"
                        size="small"
                    >
                        <AuditTrailTableHead mode={mode} />
                        {Array.isArray(auditTrailDatas) && auditTrailDatas.length > 0 &&
                            <TableBody>
                                {auditTrailDatas.map((row, index) =>
                                    values(row, index)
                                )}
                            </TableBody>
                        }
                    </Table>
                </TableContainer>
            </Paper>

            {!hasScrollBar && auditTrailDatas.length >= (CONST.auditTrail.limitValue) &&
                <div id="load-more-div">
                    <Button
                        id="load-more-btn"
                        variant="outlined"
                        onClick={e => onScrollTableBottom()}
                    >
                        {t(TRANS_MAP.loadMoreDatas)}
                    </Button>
                </div>}
            {!isLoading && auditTrailDatas.length === 0 && <Grid container id="audit-trail-no-data">No Datas</Grid>}
            {isLoading &&
                <>
                    <div id="loading-message">{t(TRANS_MAP.loadingMessage)}</div>
                    <LinearProgress />
                </>
            }
        </Box>
    );
}

export default AuditTrailTable;
