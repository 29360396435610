import React, { useState, useContext } from 'react';
import { Button, Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import SyncIcon from '@mui/icons-material/Sync';

import { AppContext } from '@/context/AppContext';
import PrintingsTable from './PrintingsTable/Table';
import TRANS_MAP from '@/i18n/TransMap';
import * as audit from '@/libs/AuditTrailOperations';

import CONST from '@/const';
import './Printings.css';

const Printings = ({ mode }) => {
    const { t } = useTranslation();

    const PRODUCT_LABEL = t(TRANS_MAP.materialLabel);
    const BATCH_LABEL = t(TRANS_MAP.batchLabel);
    const COUNTRY_LABEL = t(TRANS_MAP.countryLabel);
    const OUTBOUND_LABEL = t(TRANS_MAP.outboundDelivery);
    const DOCUMENTS_LABEL = t(TRANS_MAP.documentsLabel);
    const MASS_UPDATE_LABEL = t(TRANS_MAP.massCopiesUpdate);
    const PLANT_LABEL = t(TRANS_MAP.receivingPlantLabel);
    const SERIAL_LABEL = t(TRANS_MAP.serialNbLabel);

    // Context states
    const {
        printings,
        material,
        shiporder,
        batch,
        serialNb,
        country,
        setPrintsPerDoc,
        receivingPlant,
    } = useContext(AppContext);

    // Local states
    const [inputPrints, setInputPrints] = useState(1);


    // Update the number of copies to print for every printings in the Printings Table
    const handleSetPrints = async (nbPrints) => {
        /* istanbul ignore next */
        if (parseInt(nbPrints) < 0) {
            setInputPrints(0);
        }
        setPrintsPerDoc(nbPrints);
    }

    let columns = [
        {
            id: "plant",
            label: PLANT_LABEL,
            value: receivingPlant
        },
        batch ? {
            id: "batch",
            label: BATCH_LABEL,
            value: batch
        }:{},
        serialNb ? {
            id: "serialNb",
            label: SERIAL_LABEL,
            value: serialNb
        } : {},
        {
            id: "country",
            label: COUNTRY_LABEL,
            value: country
        },
        {
            id: "outbound",
            label: OUTBOUND_LABEL,
            value: shiporder || "N/A"
        },
    ]

    const modeCol = (mode === audit.AVAILABLE_MODE.IN) ? CONST.columnsInbound : CONST.columnsOutbound;
    columns = columns.filter(el => modeCol.includes(el.id));

    return (
        <Grid container
            id="printings-body"
        >
            {/* Summary */}
            <Grid container
                id="printings-summary">
                <h3>{PRODUCT_LABEL}: {material}</h3>
                <Grid container
                    direction="row"
                    spacing={3}
                    id="summary-info"
                >
                    {columns.map(column => (
                        <Grid item key={column.label}
                            className="summary-item">
                            <div className="summary-label">{column.label}</div>
                            <div>{column.value}</div>
                        </Grid>
                    ))}
                    <Grid item className="summary-item"/>
                </Grid>
            </Grid>

            {/* Documents / Printings */}
            <Grid container
                id="documents-body"
                direction="column"
            >
                {
                    mode === audit.AVAILABLE_MODE.OUT ?
                    <>
                        <h3>{DOCUMENTS_LABEL}</h3>

                        <div>{MASS_UPDATE_LABEL}</div>

                        <div id="total-prints-container">
                            <input
                                id="nb-copies"
                                type="number"
                                min={0}
                                defaultValue={0}
                                onInput={
                                    // Requires a solution to simulate an user type with integer value
                                    /* istanbul ignore next */
                                    (e) => setInputPrints(e.target.value)
                                }
                                disabled={!shiporder}
                            />
                            <Button
                                id="set-prints-btn"
                                variant="contained"
                                onClick={() => handleSetPrints(inputPrints)}
                                disabled={!shiporder}
                            >
                                <SyncIcon />
                                {t(TRANS_MAP.apply)}
                            </Button>
                        </div>
                    </>
                    : 
                    <></>
                }
                {/* Wait for printings to be set before displaying the Printings Table */}
                {printings.length > 0 &&
                    <Grid container id="table-container">
                        <PrintingsTable mode={mode} />
                    </Grid>}
            </Grid>
        </Grid>
    );
}

export default Printings;
