const EN_TRANSLATION = {
    title: "Title",
    appTitle: "Customization On Demand",
    appDescription: "App Description",
    homePage: "Home",
    auditTrailPage: "Audit Trail",
    printingsPage: "Printings",
    logoutMenu: "Log out",

    customizationDataBox: "Customization data",
    shipOrderLabel: "Outbound delivery",
    countryLabel: "Country",
    materialLabel: "Material",
    batchLabel: "Batch",
    quantityLabel: "Quantity",
    printButton: "Print",
    customizeButton: "Customize",

    printerSelectionBox: "Printer selection",
    A4PrinterLable: "A4 Printer",
    zebraPrinterLabel: "Label Printer",

    auditTrailMenu: "Audit Trail",
    customizationMenu: "Customization",

    userMyProfile: "My profile",
    userContactInfo: "Contact information",
    userSite: "Site",
    userEmail: "Email",

    printingStatusTable: "Printing status",
    printingsLabel: "Printings",
    documentType: "Document type",
    partNumber: "Part number",
    languages: "Language",
    action: "Action",
    status: "Status",
    format: "Format",
    validate: "Validate",

    "status-open": "Not printed",
    "status-aws-bad-product": "Unknown material",
    "status-aws-bad-batch": "Unknown batch",
    "status-sap-no-custo": "No customization needed for this country and item",
    "status-aws-pdf-missing": "PDF missing",
    "status-aws-pdf-multiple": "Multiple documents found for this material/part number/language",
    "print-validated": "Validated",
    "status-sap-missing-doctype": "Document type missing in batch table",
    "print-initiated": "Print initiated",
    "no-document": "No document for the docType/batch",
    "document-missing": "Document missing",
    "receiving-plant-length": "Bad length for ReceivingPlant",
    "status-sap-batch-managed-required": "Batch missing for this customization",
    packageInsert: "Package Insert",
    outerContainerLabel: "Label",

    itemsLabel: "Items",
    documentsLabel: "Documents",

    messageNoCustoNeeded: "No customization needed for this country and item",
    messageNoDataForShipOrder: "No item and lot data for this ship order",
    messageBadItem: "No such EAN or material number. Please check the item field value or enter the code manually.",
    messageBadBatch: "EAN and Batch unknown for customization (YMTAMATCT).",
    messageTechnicalError: "Error occured when trying to retrieve data.",
    messagePDFMissing: "PDF missing in Resource Center",
    messagePDFMultiple: "Multiple documents found for this material/part number/language",

    busyTitle: "Action in progress",
    busyText: "We are searching customization documents, please wait...",
    shiporderBusyText: "We are searching for existing ship order datas, please wait...",

    errorRequiredFields: "Please complete the required fields.",

    auditTrailTitle: "Audit Trail",
    resetAllFilters: "Reset All Filters",
    filterAll: "All operations",
    filterValidated: "Validated",
    filterNotValidated: "Not Validated",
    filterFailed: "Failed",
    auditTrailTableTitle: "History",
    dateLabel: "Date",
    modifiedByLabel: "Modified by",
    siteLabel: "Site",
    printingStatusLabel: "Printing status",
    searchTooltip: "Search",

    sessionTimeOut: "Your session has timed out. Please refresh your page to continue.",
    sortLabel: "Sort data",
    exportLabel: "Export data",
    clearDateLabel: "Clear selected dates",

    inputShipOrderLabel: "Input a ship order (optional)",
    inputCountryLabel: "Input a country (mandatory)",
    inputMaterialLabel: "Input an item number (mandatory)",
    inputBatchLabel: "Input a batch number (optional)",

    ConfirmValidateTitle: "Confirm",
    ConfirmValidate: "One or many printings do not have status Print initiated.\nDo you still want to validate ?",
    Confirm: "Confirm",
    Cancel: "Cancel",
    apply: "Apply",

    loadingMessage: "Loading datas...",
    download: "Download",
    preparingDatas: "Preparing datas to be exported...",
    datasReady: "File ready to be downloaded",

    tileTitleOut: "Outbound deliveries customization",
    tileSubtitle: "",
    tileInfo: "v2.0",
    tileTitleIn: "Inbound deliveries customization",

    labelRef: "Label reference",
    massCopiesUpdate: "Set number of copies on all selected items at once:",
    copies: "copie(s)",
    copiesLabel: "Copies",
    copiesToDoLabel: "Copies to do",
    copiesPrintedLabel: "Copies already printed",
    outboundDelivery: "Outbound delivery",
    quantity: "Quantity",
    missingInputs: "Input(s) missing",
    unExpectedTechnicalError: "Unexpected technical error occured",
    printedQuantities: "Printed quantities",
    documentNumber: "Document number",

    warning: "Warning",
    loadMoreDatas: "Load More Datas",

    outboundDeliveries: "Outbound customization",
    inboundLabelling: "Inbound customization",
    customization: "Customization",

    purchaseOrderLabel: "Purchase Order",
    receivingPlantLabel: "Receiving Plant",
    manufacturingDateLabel: "Manufacturing Date",
    expirationDateLabel: "Expiration Date",
    serialNbLabel: "Serial #",
    inboundQuantityLabel: "Quantity / Logistic Unit",


    inputPurchaseOrderLabel: "Input a Purchase Order (Optional)",
    inputReceivingPlantLabel: "Input your Receiving Plant SAP (Mandatory)",
    inputManufacturingDateLabel: "Input a Manuf. Date dd.mm.YYYY (Optional)",
    inputExpirationDateLabel: "Input a Expiry Date dd.mm.YYYY (Optional)",
    inputSerialNbLabel: "Input a Serial number (Optional)",
    inputBatchInboundLabel: "Input a Batch number (Optional)",
    inputQuantityLabel: "(Optional) - 1 by default",

    errorBatchOrSerial: "Please complete either Batch or Serial # field.",
}

export default EN_TRANSLATION;