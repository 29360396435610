import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import FR_TRANSLATIONS from "@/i18n/i18n_fr.js";
import EN_TRANSLATIONS from "@/i18n/i18n_en.js";

const options = {
    order: [navigator],
}

i18n
    .use(initReactI18next)
    // init i18next
    .init({
        detection: options,
        debug: false,
        lng: navigator.language,
        fallbackLng: navigator.language,
        interpolation: {
            escapeValue: false, // not needed for react as it escapes by default
        },
        resources: {
            en: {
                translation: EN_TRANSLATIONS
            },
            "en-US": {
                translation: EN_TRANSLATIONS
            },
            fr: {
                translation: FR_TRANSLATIONS
            },
            "fr-FR": {
                translation: FR_TRANSLATIONS
            },
        }
    });

export default i18n;
