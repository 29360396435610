import Grid from '@mui/material/Grid';
import { useTranslation } from 'react-i18next';
import TRANS_MAP from '@/i18n/TransMap';

import './Header.css';

const Header = () => {
    const { t } = useTranslation();
    const APP_TITLE = t(TRANS_MAP.appTitle);

    return (
        <Grid container
            id="header-container"
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <img src="/assets/bmx-logo.png" id="header-bmx-logo" alt="biomerieux logo" />
            {APP_TITLE}
        </Grid>
    );
}

export default Header;
