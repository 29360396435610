import React from "react";
import { useTranslation } from 'react-i18next';
import { Divider, Grid, Paper, Typography, useMediaQuery, useTheme } from '@mui/material';
import { NavLink } from "react-router-dom";

import TRANS_MAP from "@/i18n/TransMap";

import './Home.css'
import CONST from "@/const";

const Home = () => {
    const theme = useTheme();
    const largeScreen = useMediaQuery(theme.breakpoints.down("md"));
    const { t } = useTranslation();

    return (
        <Grid container
            id="homepage-body"
            direction="row"
            justifyContent="space-around"
            alignItems="center"
        >
            <Grid item container
                spacing={6}
                justifyContent="center"
                alignItems="center"
                /* istanbul ignore next */ 
                direction={largeScreen ? "column" : "row"}
                display='flex'
            >
                <Grid item xs={3}
                    id="home-paper-grid">
                    <Paper
                        id="home-paper">
                        <Grid container
                            id="home-form-header"
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography>
                                {t(TRANS_MAP.inboundLabelling)}
                            </Typography>
                        </Grid>
                        <Divider />
                        <div
                            id="home-cod-inbound">
                            <NavLink to={CONST.frontRoutes.homeInbound} className='inbound-button' >
                                <img src="/assets/in.png" id="home-inbound" className="home-icon" alt="Inbound logo" />
                            </NavLink>
                        </div>
                    </Paper>
                </Grid>

                <Grid item
                    id="home-cod-logo-item">
                    <img src="/assets/logo-custom-tool.png" id="home-cod-logo" alt="COD Logo" />
                </Grid>

                <Grid item xs={3}
                    id="home-paper-grid">
                    <Paper
                        id="home-paper">

                        {/* Form Header */}
                        <Grid container
                            id="home-form-header"
                            direction="row"
                            justifyContent="center"
                            alignItems="center"
                        >
                            <Typography>
                                {t(TRANS_MAP.outboundDeliveries)}
                            </Typography>
                        </Grid>

                        <Divider />
                        <div
                            id="home-cod-outbound">
                            <NavLink to={CONST.frontRoutes.homeOutbound} className='outbound-button'>
                                <img src="/assets/out.png" id="home-outbound" className="home-icon" alt="Outbound logo" />
                            </NavLink>
                        </div>
                    </Paper>
                </Grid>
            </Grid>
        </Grid>
    );
}

export default Home;
