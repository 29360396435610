const CONST = {
    AIMIdentifer: {
        items: ['01', '02'],
        expiryDate: '17',
        lot: '10',
        serial: '21',
    },
    status: {
        open: 'status-open',
        printInitiated: 'print-initiated',
        printValidated: 'print-validated',
        badProduct: 'status-aws-bad-product',
        badBatch: 'status-aws-bad-batch',
        noCusto: 'status-sap-no-custo',
        multiplePDF: 'status-aws-pdf-multiple',
        missingPDF: 'status-aws-pdf-missing',
        missingDocType: 'status-sap-missing-doctype',
        noDocument: 'no-document',
        documentMissing: 'document-missing',
        receivingPlantLength: 'receiving-plant-length',
        batchManagedRequired: "status-sap-batch-managed-required"
    },
    docTypes: {
        UNKNOWN: { code: '00', name: "UNKNOWN", abbr: 'N/A', label: 'N/A', img: '/assets/missing-doc-icon.png' },
        PI: { code: '01', name: "PI", abbr: 'PI', label: 'Package Insert', img: '/assets/a4-icon.png' },
        OCL: { code: '02', name: "OCL", abbr: 'OCL', label: 'Label', img: '/assets/zebra-icon.png' },
        RA: { code: '03', name: "RA", abbr: 'RA', label: 'RA Label', img: '/assets/zebra-icon.png' },
        LL: { code: '04', name: "LL", abbr: 'LL', label: 'Logistic Label', img: '/assets/zebra-icon.png' },
        TPPM: { code: "10", name: "TPPM", abbr: "TPPM", label: "TPP Material", img: '/assets/zebra-icon.png' },
        LATAMM: { code: "11", name: "LATAMM", abbr: "LATAMM", label: "LATAM Material", img: '/assets/zebra-icon.png' },
        INDIAM: { code: "12", name: "INDIAM", abbr: "INDIAM", label: "INDIA Material", img: '/assets/zebra-icon.png' },
        OTHERM: { code: "13", name: "OTHERM", abbr: "OTHERM", label: "Other Material", img: '/assets/zebra-icon.png' },
        TPPG: { code: "20", name: "TPPG", abbr: "TPPG", label: "TPP Global", img: '/assets/zebra-icon.png' },
        LATAMG: { code: "21", name: "LATAMG", abbr: "LATAMG", label: "LATAM Global", img: '/assets/zebra-icon.png' },
        INDIAG: { code: "22", name: "INDIAG", abbr: "INDIAG", label: "INDIA Global", img: '/assets/zebra-icon.png' },
        OTHERG: { code: "23", name: "OTHERG", abbr: "OTHERG", label: "Other Global", img: '/assets/zebra-icon.png' },
    },
    docTypesPrintings: {
        DIRECT: ["01", "02"],
        GLT: ["03", "04", "10", "11", "12", "13", "20", "21", "22", "23"]
    },
    apiRoutes: {
        getGltDocument: "/getgltdocument",
        gltDocExists: "/gltdocexists",
        countryCusto: "/countrycusto",
        getRCDocuments: "/getrcdocuments",
        getDatasFromShiporder: "/getdatasfromshiporder",
        valid: "/valid",
        inboundCusto: "/inboundcusto",
    },
    crudRoutes: {
        operations: "/operations",
        printings: "/printings",
        audittrail: "/audittrail",
    },
    frontRoutes: {
        home: "/",
        homeOutbound: "/outbound",
        printingsOutbound: "/outbound/printings",
        auditTrailOutbound: "/outbound/audit-trail",
        homeInbound: "/inbound",
        printingsInbound: "/inbound/printings",
        auditTrailInbound: "/inbound/audit-trail",
    },
    order: {
        asc: "asc",
        desc: "desc"
    },
    tabFilters: {
        all: "all-ops",
        validated: "validated-ops",
        notValidated: "not-validated-ops",
        failed: "failed-ops"
    },
    sortParameters: {
        date: "date"
    },
    auditTrail: {
        limitKey: "limit",
        limitValue: 20,
        statusKey: "status",
        countKey: "count",
        countValue: "true",
        sort: "sort",
        order: "order",
        from: "from",
        to: "to",
        search: "search",
        status: "status"
    },
    dbFields: {
        item: "item",
        material: "material",
        shiporder: "shiporder",
        receiving_plant: "receiving_plant",
        serial_number: "serial_number",
        updatedAt: "updated_at",
        country: "country",
        batch: "batch",
        modifiedBy: "modified_by",
        documentType: "document_type",
        partNumber: "part_number",
        labelPart: "label_part",
        languages: "languages",
        status: "status",
        quantity: "quantity",
    },
    events: {
        scroll: "scroll",
    },
    navigator: {
        languagesFR: ["fr", "fr-FR"],
    },
    dateFormats: {
        en: "MM/d/yyyy",
        fr: "dd/MM/yyyy",
    },
    columnsPrintingsOutbound: [
        "documentType",
        "documentNumber",
        "ref",
        "languages",
        "status",
        "format",
        "copiesNb",
        "print-btn"
    ],
    columnsPrintingsInbound: [
        "documentType",
        "ref",
        "status",
        "format",
        "print-btn"
    ],
    columnsAuditTrailOutbound: [
        "material",
        "batch",
        "country",
        "shiporder",
        "document_type",
        "part_number",
        "languages",
        "status",
        "quantity",
        "modified_by",
        "updated_at"
    ],
    columnsAuditTrailInbound: [
        "material",
        "batch",
        "country",
        "receiving_plant",
        "serial_number",
        "document_type",
        "part_number",
        "languages",
        "status",
        "modified_by",
        "updated_at"
    ],
    columnsAuditTrailInboundExport: [
        "material",
        "batch",
        "country",
        "receiving_plant",
        "serial_number",
        "document_type",
        "label_part",
        "languages",
        "status",
        "modified_by",
        "updated_at"
    ],
    columnsInbound: [
        "plant",
        "batch",
        "serialNb"
    ],
    columnsOutbound: [
        "batch",
        "country",
        "outbound"
    ],
    keyboardKeys: {
        ENTER: 13
    }

}

export default CONST;
