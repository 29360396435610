import { useContext, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import BlockIcon from '@mui/icons-material/Block';
import ClearIcon from '@mui/icons-material/Clear';
import DescriptionOutlinedIcon from '@mui/icons-material/DescriptionOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { Grid, InputAdornment, IconButton, OutlinedInput, Button } from '@mui/material';
import DatePicker from 'react-datepicker';

import TRANS_MAP from '@/i18n/TransMap';
import { AppContext } from '@/context/AppContext';
import DialogExport from '../DialogExport';
import CONST from '@/const';

import 'react-datepicker/dist/react-datepicker.css';

const AuditTrailTableToolbar = ({ mode }) => {
    let dateFormat = CONST.dateFormats.en;
    /* istanbul ignore next */
    if (CONST.navigator.languagesFR.includes(navigator.language))
        dateFormat = CONST.dateFormats.fr;

    const { t } = useTranslation();

    const SEARCH_PH = t(TRANS_MAP.searchTooltip);
    const DATES_PH = `${dateFormat} - ${dateFormat}`;

    // Local states
    const [openExport, setOpenExport] = useState(false);

    // Context states
    const {
        dateRange, setDateRange,
        research, setReasearch,
        filters, setFilters, updateFilters, resetFilters
    } = useContext(AppContext);

    const [startDate, endDate] = dateRange;

    const handleDateRangeFilter = () => {
        setFilters({
            ...filters,
            [CONST.auditTrail.from]: startDate.getTime(),
            [CONST.auditTrail.to]: endDate.getTime()
        })
    }

    const handleResetDateRange = () => {
        setDateRange("", "");
        setFilters({
            ...filters,
            [CONST.auditTrail.from]: "",
            [CONST.auditTrail.to]: ""
        })
    }

    const handleResearchChange = (e) => {
        setReasearch(e.target.value);
    }

    const clearSearchField = () => {
        setReasearch("");
        updateFilters({ key: CONST.auditTrail.search, value: "" })
    }

    const triggerSearch = () => {
        if (!research)
            return
        updateFilters({ key: CONST.auditTrail.search, value: research })
    }

    const exportToFile = () => {
        setOpenExport(true);
    }

    const handleCloseExport = () => {
        setOpenExport(false);
    }

    useEffect(() => {
        if (startDate && endDate) {
            handleDateRangeFilter();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [startDate, endDate]);

    return (
        <Grid container
            id="audit-trail-toolbar-container"
            direction="row"
            justifyContent="space-between"
        >
            <Grid item
                id="audit-trail-toolbar-title">
                <span>{t(TRANS_MAP.auditTrailTableTitle)}</span>

            </Grid>
            <Grid container item
                id="audit-trail-toolbar-fields"
            >
                <Button variant="outlined" onClick={resetFilters} id="reset-filter-btn">{t(TRANS_MAP.resetAllFilters)}</Button>

                <DatePicker
                    dateFormat={dateFormat}
                    wrapperClassName="date-picker-wrapper"
                    id="datepicker"
                    selectsRange={true}
                    startDate={startDate}
                    endDate={endDate}
                    onChange={(update) => {
                        setDateRange(update);
                    }}
                    placeholderText={DATES_PH}
                />
                <IconButton
                    id="clear-dates-btn"
                    aria-label="clear dates"
                    onClick={() => handleResetDateRange()}
                    edge="end"
                >
                    <BlockIcon />
                </IconButton>

                <OutlinedInput
                    id="input-research"
                    className="filter-fields"
                    type="text"
                    value={research}
                    onChange={(e) => handleResearchChange(e)}
                    placeholder={SEARCH_PH}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                className={research ? '' : 'invisible'}
                                aria-label="clear search"
                                onClick={() => clearSearchField()}
                                edge="end"
                            >
                                <ClearIcon />
                            </IconButton>
                            <IconButton
                                aria-label="trigger search"
                                onClick={() => triggerSearch()}
                                edge="end"
                            >
                                <SearchIcon />
                            </IconButton>
                        </InputAdornment>
                    }
                />
                <IconButton
                    id="export-file-btn"
                    aria-label="export to file"
                    onClick={() => exportToFile()}
                    edge="end"
                >
                    <DescriptionOutlinedIcon />
                </IconButton>

                {openExport &&
                    <DialogExport open={openExport} handleClose={handleCloseExport} mode={mode} />
                }
            </Grid>
        </Grid>
    );
}

export default AuditTrailTableToolbar;
