import React, { useContext, useState, useEffect } from "react";
import PersonOutlineOutlinedIcon from '@mui/icons-material/PersonOutlineOutlined';
import TableChartOutlinedIcon from '@mui/icons-material/TableChartOutlined';
import HomeOutlinedIcon from '@mui/icons-material/HomeOutlined';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import { Button, Grid } from "@mui/material";
import { Link, useLocation } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { AppContext } from "@/context/AppContext";

import './Navbar.css';
import CONST from "@/const";

const Navbar = () => {
    const [pageTitle, setPageTitle] = useState("");

    const { user } = useContext(AppContext);
    const userMail = user.attributes.email;

    const location = useLocation();
    const { t } = useTranslation();

    const HOME_PAGE_TITLE = "homePage";
    const OUTBOUND_HOME_PAGE = "outboundDeliveries";
    const INBOUND_HOME_PAGE = "inboundLabelling";
    const AUDITTRAIL_PAGE_TITLE = "auditTrailPage";
    const PRINTINGS_PAGE_TITLE = "printingsPage";
    const CUSTOMIZATION = "customization";

    useEffect(() => {
        const path = location.pathname.split('/');
        if (path.length > 2) {
            switch (location.pathname.split('/')[2]) {
                case 'printings':
                    setPageTitle(PRINTINGS_PAGE_TITLE);
                    break;
                case 'audit-trail':
                    setPageTitle(AUDITTRAIL_PAGE_TITLE);
                    break;
                /* istanbul ignore next */
                default:
                    break;
            }
        }
        else {
            switch (location.pathname.split('/')[1]) {
                case 'outbound':
                    setPageTitle(OUTBOUND_HOME_PAGE);
                    break;
                case 'inbound':
                    setPageTitle(INBOUND_HOME_PAGE);
                    break;
                default:
                    setPageTitle(HOME_PAGE_TITLE);
            }
        }
    }, [location]);


    const renderNavBtn = () => {
        const { pathname } = location;
        const [, app, page] = pathname.split("/");

        if (pathname === CONST.frontRoutes.home) return;
        return (
            <>
                { page && <Link to={`/${app}`} underline="none" className="link-btns">
                    <Button id="nav-btn">
                        <NavigateBeforeIcon />
                        {t(CUSTOMIZATION)}
                    </Button>
                </Link> }
                { !page && <Link to={`/${app}/audit-trail`} className="link-btns">
                    <Button id="nav-btn">
                        <TableChartOutlinedIcon />
                        {t(AUDITTRAIL_PAGE_TITLE)}
                    </Button>
                </Link> }
                <Link to={CONST.frontRoutes.home} underline="none" className="link-btns">
                    <Button id="nav-btn">
                        <HomeOutlinedIcon />
                        {t(HOME_PAGE_TITLE)}
                    </Button>
                </Link>
            </>
        );
    }

    return (
        <Grid container
            id="nav-bar-container"
            direction="row"
            justifyContent="space-between"
            alignItems="center"
        >
            {/* User icon and mail */}
            <Grid container
                item
                xs="auto"
                justifyContent="flex-start"
                alignItems="center"
                id="nav-user-item"
            >
                <PersonOutlineOutlinedIcon />
                <span>{userMail}</span>
            </Grid>

            {/* Page Name */}
            <Grid item id="nav-title-item" xs={3}>{t(pageTitle)}</Grid>

            {/* Navigation button */}
            <Grid item id="nav-btn-item" xs={2}>{renderNavBtn()}</Grid>

        </Grid>
    );
}

export default Navbar;
