import { React, useState, useContext, useEffect } from 'react';
import { useHistory } from 'react-router';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Checkbox from '@mui/material/Checkbox';
import BlockIcon from '@mui/icons-material/Block';
import LocalPrintshopOutlinedIcon from '@mui/icons-material/LocalPrintshopOutlined';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useTranslation } from 'react-i18next';
import { Button } from '@mui/material';
import PrintingsTableHead from './TableHead';
import PrintingsTableToolbar from './TableToolBar';
import printJS from 'print-js';

import * as utils from '@/libs/utils';
import * as custo from '@/libs/Customize';
import * as audit from '@/libs/AuditTrailOperations';

import CONST from '@/const';
import TRANS_MAP from '@/i18n/TransMap';
import { AppContext } from '@/context/AppContext';
import PrintingsDialog from '../PrintingsDialog';
import LoadingBackdrop from '@/components/Common/LoadingBackdrop';

const PrintingsTable = ({ mode }) => {
    const { t } = useTranslation();
    const history = useHistory();

    // Context states
    const {
        user,
        operation,
        printings, setPrintings,
        printsPerDoc, setPrintsPerDoc,
        openAlertDialog,
        inboundField,
    } = useContext(AppContext);

    // Local states
    const [selected, setSelected] = useState([]);
    const [disableValidate, setDisableValidate] = useState(true);
    const [openDialog, setOpenDialog] = useState(false);
    const [isBusy, setIsBusy] = useState(false);


    useEffect(() => {
        // If all printings have status "print-validated" we go back to Home
        const checkAllValidated = async () => {
            if (printings.every(p => p.status_message === CONST.status.printValidated)) {
                setIsBusy(true);
                await audit.updateOperation({ user, operation, status: CONST.status.printValidated })
                setIsBusy(false);

                switch (mode) {
                    case audit.AVAILABLE_MODE.IN:
                        history.push(CONST.frontRoutes.homeInbound);
                        break;
                    case audit.AVAILABLE_MODE.OUT:
                        history.push(CONST.frontRoutes.homeOutbound);
                        break;
                    /* istanbul ignore next */
                    default:
                        history.push(CONST.frontRoutes.home);
                }
            }
        }
        checkAllValidated();
    }, [printings, history, operation, user, mode])


    // Launch printing
    const handlePrint = async (event, row, index) => {
        event.preventDefault();
        setIsBusy(true);
        try {
            if (CONST.docTypesPrintings.DIRECT.includes(row.document_type)) {
                printJS({ printable: printings[index].temporary_url, type: 'pdf', showModal: true });
            } else if (CONST.docTypesPrintings.GLT.includes(row.document_type)) {
                const printingBase64 = await custo.getGLTDocumentPDF(
                    printings[index].material,
                    row.label_part,
                    operation.batch,
                    operation.country,
                    inboundField,
                    {
                        manufacturer: printings[index].manufacturer,
                        manufacturer_country: printings[index].manufacturer_country,
                    });
                printJS({ printable: printingBase64, type: 'pdf', base64: true, showModal: true });
            }
            // Set status to "print-initiated"
            await audit.updatePrinting({ printing_id: printings[index].id, status: CONST.status.printInitiated });
            printings[index].status_message = CONST.status.printInitiated;
        } catch (error) {
            /* istanbul ignore next */
            console.error(error);
        }
        setIsBusy(false);
    }

    // handlers for printings validations in dialog
    const handleCancel = () => setOpenDialog(false);

    const handleConfirmValidate = async () => {
        // loop through selected printings to set their statuses to "print-validated"
        for (let index of selected) {
            printings[index].status_message = CONST.status.printValidated;
            await audit.updatePrinting({ printing_id: printings[index].id, status: CONST.status.printValidated });
        }
        setPrintings([...printings]);
        setOpenDialog(false);
    }

    // Validate selected printing, ie. set printing status to "print-validated"
    const handleClickValidate = async () => {
        // If at least one of the selected printings are still in open status, ask for validation
        for (let index of selected) {
            let docStatus = printings[index].status_message;
            if (![CONST.status.printInitiated, CONST.status.printValidated].includes(docStatus)) {
                setOpenDialog(true);
                return;
            }
        }
        // Else set the selected printings statuses to "print-validated" without confirmation
        await handleConfirmValidate();
    }

    // Handle clicking on the global checkbox
    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            let newSelecteds = printings.map((n, index) => {
                if (!utils.hasError(n.status_message))
                    return index;
                /* istanbul ignore next */
                return undefined;
            });
            newSelecteds = newSelecteds.filter(value => value !== undefined)
            setSelected(newSelecteds);
            setDisableValidate(newSelecteds.length === 0);
            return;
        }
        setDisableValidate(true);
        setSelected([]);
    };

    const handleSelect = (_event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }
        setSelected(newSelected);
        setDisableValidate(newSelected.length === 0);
    };

    const isSelected = (name) => selected.indexOf(name) !== -1;

    // Update number of copies for each selected row
    useEffect(() => {
        // Requires a solution to simulate an user type with integer value
        /* istanbul ignore next */
        const applyPrintsPerDoc = async () => {
            if (printsPerDoc === null)
                return;
            try {
                for (const index of selected) {
                    let print = printings[index];
                    print.quantity = printsPerDoc;
                    setPrintings([...printings]);
                    await audit.updatePrinting({ printing_id: print.id, quantity: parseInt(printsPerDoc) });
                }
            } catch (error) {
                openAlertDialog({ title: TRANS_MAP.warning, content: TRANS_MAP.messageTechnicalError, infos: error.message })
            }
            setPrintsPerDoc(null);
        }
        applyPrintsPerDoc();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [printsPerDoc])

    let rowOkCount = printings.filter(print => !utils.hasError(print.status_message)).length;

    const values = (row, index, rowHasError) => {
        const cols = [
            mode === audit.AVAILABLE_MODE.OUT ? { key: "part_number", value: row.part_number } : null,
            { key: "label_part", value: row.label_part },
            mode === audit.AVAILABLE_MODE.OUT ? { key: "languages", value: row.languages } : null,
            { key: "status_message", value: t(row.status_message) },
            { key: "document_type", value: utils.getDocumentTypeIcon(row.document_type), padding: "none" },
            mode === audit.AVAILABLE_MODE.OUT ? { key: "quantity", value: rowHasError ? "x" : `${row.quantity}/${operation.quantity}` } : null,
        ].filter(Boolean);

        return <>
            {
                cols.map(col =>
                    <TableCell key={`${row.document_type}_${index}_${col.key}`} align="left" padding={col.padding}>{col.value}</TableCell>

                )
            }

        </>
    }

    return (
        <>
            <Box sx={{ width: '100%' }}>
                <Paper sx={{ width: '100%', mb: 2 }}>
                    <PrintingsTableToolbar numSelected={selected.length} />
                    <TableContainer>
                        <Table
                            sx={{ minWidth: 750 }}
                            aria-labelledby="tableTitle"
                            size={'medium'}
                        >
                            <PrintingsTableHead
                                numSelected={selected.length}
                                onSelectAllClick={handleSelectAllClick}
                                rowCount={printings.length}
                                rowOkCount={rowOkCount}
                                mode={mode}
                            />
                            <TableBody>
                                {printings
                                    .map((row, index) => {
                                        const rowHasError = utils.hasError(row.status_message);
                                        const isItemSelected = isSelected(index);
                                        const labelId = `enhanced-table-checkbox-${index}`;

                                        return (
                                            <TableRow
                                                role="checkbox"
                                                aria-checked={isItemSelected}
                                                tabIndex={-1}
                                                key={`${row.document_type}_${index}`}
                                                selected={isItemSelected}
                                                className={rowHasError ? "disabled-row" : ""}
                                                hover={!rowHasError}
                                            >
                                                <TableCell padding="checkbox">
                                                    <Checkbox
                                                        onClick={(event) => handleSelect(event, index)}
                                                        color="primary"
                                                        checked={isItemSelected}
                                                        disabled={rowHasError}
                                                        inputProps={{
                                                            'aria-labelledby': labelId,
                                                        }}
                                                    />
                                                </TableCell>
                                                <TableCell
                                                    className="table-bold-col"
                                                    id={labelId}
                                                    scope="row"
                                                    padding="none"
                                                >
                                                    {utils.getDocTypeLabelFromCode(row.document_type)}
                                                </TableCell>
                                                {
                                                    values(row, index, rowHasError)
                                                }
                                                <TableCell align="center" padding="none">
                                                    {rowHasError
                                                        ?
                                                        <Button
                                                            variant="contained"
                                                            disabled
                                                        >
                                                            <BlockIcon />
                                                            {t(TRANS_MAP.printButton)}
                                                        </Button>
                                                        :
                                                        <Button
                                                            variant="contained"
                                                            onClick={(event) => handlePrint(event, row, index)}
                                                        >
                                                            <LocalPrintshopOutlinedIcon />
                                                            {t(TRANS_MAP.printButton)}
                                                        </Button>
                                                    }
                                                </TableCell>
                                            </TableRow>
                                        );
                                    })}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Paper>
                <Button
                    id="validate-btn"
                    color="success"
                    variant="contained"
                    disabled={disableValidate}
                    onClick={() => handleClickValidate()}
                >
                    <CheckCircleIcon />
                    {t(TRANS_MAP.validate)}
                </Button>
            </Box>
            {/* Validation confirmation dialog */}
            <PrintingsDialog open={openDialog} handleConfirm={handleConfirmValidate} handleCancel={handleCancel} />
            {isBusy && <LoadingBackdrop />}
        </>
    );
}

export default PrintingsTable;
