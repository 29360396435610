import React from "react";
import { Grid } from "@mui/material";
import { version } from '@/../package.json';
import './Footer.css';

const Footer = () => {
    const VERSION = `v${version}`;

    return (
        <Grid container
            id="footer-container"
            direction="row"
            justifyContent="center"
            alignItems="center"
        >
            <div>{VERSION}</div>
        </Grid>
    );
}

export default Footer;
