import PropTypes from 'prop-types';
import TableCell from '@mui/material/TableCell';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Checkbox from '@mui/material/Checkbox';
import { useTranslation } from 'react-i18next';

import CONST from '@/const';
import * as audit from '@/libs/AuditTrailOperations';

import TRANS_MAP from '@/i18n/TransMap';

const PrintingsTableHead = (props) => {
    const { t } = useTranslation();

    let headCells = [
        {
            id: 'documentType',
            numeric: false,
            disablePadding: true,
            label: t(TRANS_MAP.documentType),
        },
        {
            id: 'documentNumber',
            numeric: false,
            disablePadding: false,
            label: t(TRANS_MAP.partNumber),
        },
        {
            id: 'ref',
            numeric: false,
            disablePadding: true,
            label: t(TRANS_MAP.labelRef),
        },
        {
            id: 'languages',
            numeric: false,
            disablePadding: false,
            label: t(TRANS_MAP.languages),
        },
        {
            id: 'status',
            numeric: false,
            disablePadding: false,
            label: t(TRANS_MAP.status),
        },
        {
            id: 'format',
            numeric: false,
            disablePadding: true,
            label: t(TRANS_MAP.format),
        },
        {
            id: 'copiesNb',
            numeric: true,
            disablePadding: true,
            label: t(TRANS_MAP.copiesLabel),
        },
        {
            id: 'print-btn',
            numeric: false,
            disablePadding: true,
            small: true,
            label: '',
        },
    ];


    const { onSelectAllClick, numSelected, rowCount, rowOkCount, mode } = props;
    const colMode = (mode === audit.AVAILABLE_MODE.IN) ? CONST.columnsPrintingsInbound : CONST.columnsPrintingsOutbound;
    headCells = headCells.filter(el => colMode.includes(el.id));


    return (
        <TableHead>
            <TableRow id="table-header">
                <TableCell padding="checkbox">
                    <Checkbox
                        color="primary"
                        indeterminate={numSelected > 0 && numSelected < rowOkCount}
                        checked={rowCount > 0 && numSelected === rowOkCount}
                        onChange={onSelectAllClick}
                        inputProps={{
                            'aria-label': 'select all documents',
                        }}
                    />
                </TableCell>
                {headCells.map((headCell, index) => (
                    <TableCell
                        key={index}
                        align="left"
                        padding={headCell.disablePadding ? 'none' : 'normal'}
                        size={headCell.small ? 'small' : 'medium'}
                    >
                        {headCell.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

PrintingsTableHead.propTypes = {
    numSelected: PropTypes.number.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    rowCount: PropTypes.number.isRequired,
};

export default PrintingsTableHead;
