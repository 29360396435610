export const amplifyConfig = {
    Auth: {
        identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
        region: process.env.REACT_APP_AWS_REGION,
        identityPoolRegion: process.env.REACT_APP_AWS_REGION,
        userPoolId: process.env.REACT_APP_USER_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
        oauth: {
            domain: process.env.REACT_APP_USERPOOL_DOMAIN,
            scope: [
                "email",
                "openid",
                "profile",
                "aws.cognito.signin.user.admin",
            ],
            redirectSignIn: window.location.origin,
            redirectSignOut: window.location.origin,
            responseType: "code"
        },
    },
    API: {
        endpoints: [
            {
                name: process.env.REACT_APP_AMPLIFY_API_NAME,
                endpoint: `${process.env.REACT_APP_API_BASE_URL}/${process.env.REACT_APP_ENV}`,
                region: process.env.REACT_APP_AWS_REGION
            }
        ]
    },
    Logger: {
        // logLevel: ERROR | WARN | INFO | DEBUG | VERBOSE
        logLevel: 'ERROR'
    }
};