import { useContext, } from 'react';
import { AppContext } from '@/context/AppContext';

const useBusyDialog = () => {
    const {
        setMessage,
        setOpenDialog,
        setIsAlert,
    } = useContext(AppContext);

    return {
        openBusyDialog: ({ title, content, infos }) => {
            setMessage({
                title,
                content,
                infos
            });
            setOpenDialog(true);
            setIsAlert(false);
        }
    }
}

export default useBusyDialog;